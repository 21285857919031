import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchInstallments } from "../../../api/financeiro";
import {
  Chip,
  Grid,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Container,
  Paper,
} from "@material-ui/core";
import moment from "moment";
import TableComponent from "../../table/TableComponent";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const currentDate = new Date();

const FinancialForm = ({ selectedPatient }) => {
  const [objetos, setObjetos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("all");
  const [startDate, setStartDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  );

  useEffect(() => {
    if (selectedPatient) {
      const fetchData = async () => {
        setIsLoading(true);
        const result = await fetchInstallments({
          startDate,
          endDate,
          search: "all",
          patientId: selectedPatient._id,
        });
        setObjetos(result.data);
        setIsLoading(false);
      };
      fetchData();
    }
  }, [selectedPatient]);

  const searchPatient = async () => {
    setIsLoading(true);
    const result = await fetchInstallments({
      startDate,
      endDate,
      search,
      patientId: selectedPatient._id,
    });
    setObjetos(result.data);
    setIsLoading(false);
  };

  const colunas = [
    {
      label: "Vencimento",
      name: "expiration",
      options: {
        customBodyRender: (value) => {
          return value ? moment(value).format("DD/MM/YYYY") : null;
        },
      },
    },
    { label: "Fatura", name: "number" },
    { label: "Orçamento", name: "budgetName" },
    {
      label: "Data Recebimento",
      name: "datePayment",
      options: {
        customBodyRender: (value) => {
          return value && moment(value).format("DD/MM/YYYY");
        },
      },
    },
    {
      label: "Valor Recebimento",
      name: "valuePayment",
      options: {
        customBodyRender: (value) => {
          return (
            value &&
            value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          );
        },
      },
    },
    {
      label: "Valor",
      name: "value",
      options: {
        customBodyRender: (value) => {
          return (
            value &&
            value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          );
        },
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value) => {
          let color = "";
          let label = "";
          switch (value) {
            case "paid":
              color = "#198754";
              label = "Pago";
              break;
            case "overdue":
              color = "#dc3545";
              label = "Vencido";
              break;
            default:
              color = "#0d6efd";
              label = "Aberto";
              break;
          }
          return (
            <Chip
              size="small"
              label={label}
              style={{ background: `${color}`, color: "white" }}
            />
          );
        },
      },
    },
  ];

  return (
    <Container component={Paper} style={{ padding: "16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="Data Inicial"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              value={startDate}
              onChange={(e) => setStartDate(e)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={3}>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="Data Final"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              value={endDate}
              onChange={(e) => setEndDate(e)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Filtrar pacientes"
            variant="outlined"
            select
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          >
            <MenuItem value="all">Todas</MenuItem>
            <MenuItem value="paid">Pagas</MenuItem>
            <MenuItem value="open">Abertas</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={searchPatient}
          >
            Buscar
          </Button>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableComponent colunas={colunas} objetos={objetos} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedPatient: state.patientsCreation.selectedPatient,
  };
};

export default connect(mapStateToProps)(FinancialForm);
