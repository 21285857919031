import React from "react";
import { Button, CircularProgress } from "@material-ui/core";

export default function LoadingButton({ title, loading, onClick, disabled }) {
  return (
    <Button
      startIcon={loading ? <CircularProgress size={24} /> : null}
      disabled={loading || disabled}
      onClick={onClick}
      variant="contained"
      color="primary"
      disableElevation
    >
      {title}
    </Button>
  );
}
