import { Box, Typography } from "@material-ui/core";
import Axios from "axios";
import React, { useState } from "react";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import LoadingButton from "../../buttons/LoadingButton";
import { toast } from "react-toastify";

function ClinicUploadTableProsthetics(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    let headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
    );

    headers = headers.map((item) => item.trim());
    headers.unshift("clinic_id");
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
      );
      if (headers && row.length + 1 === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          if (headers[j] === "clinic_id") {
            row.unshift(props.clinic._id);
          }
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    headers.map((c) => ({
      name: c,
      selector: c,
    }));

    setData(list);
  };

  // handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsArrayBuffer(file);
  };

  const sendImport = async () => {
    setLoading(true);
    try {
      await Axios.post("/api/proteticos/migrarTabelaPreco", data);
      toast.success("Tabela protéticos importada com sucesso");
    } catch (error) {
      toast.error("Erro ao importar protéticos");
    }
    setLoading(false);
  };

  return (
    <Box>
      <Typography style={{ marginBottom: "16px" }}>
        Importador de convênios por EXCEL
      </Typography>
      <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
      <LoadingButton title="Enviar" onClick={sendImport} loading={loading} />
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    clinic: state.auth.clinic,
  };
}

export default connect(mapStateToProps)(ClinicUploadTableProsthetics);
