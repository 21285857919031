import React, { Component } from "react";
import { connect } from "react-redux";
import { css } from "aphrodite/no-important";
import { styles } from "../forms/InputFieldStyles";

import Button from "../common/Button";
import { listarStatus, listarStatusItem } from "../../api/pacientes";

class StatusLista extends Component {
  constructor(props) {
    super(props);

    this.addStatus = this.addStatus.bind(this);
    this.removeStatus = this.removeStatus.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleChangeItemStatus = this.handleChangeItemStatus.bind(this);

    this.state = {
      status: [],
      listaStatus: [],
      valueSelectStatus: "",
      listaItensStatus: [],
      valueSelectItemStatus: "",
    };
  }

  async componentDidMount() {
    const objStatus = await listarStatus(this.props.clinica._id);
    this.setState({ listaStatus: objStatus });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status !== this.props.status) {
      this.setState({ status: [...nextProps.status] });
    }
  }

  handleChangeStatus(event) {
    const valueSelectStatus = event.target.value;
    this.setState({ valueSelectStatus });
    listarStatusItem(valueSelectStatus).then((listaItensStatus) => {
      this.setState({ listaItensStatus, valueSelectItemStatus: "" });
    });
  }

  handleChangeItemStatus(event) {
    this.setState({ valueSelectItemStatus: event.target.value });
  }

  addStatus() {
    const { getStatus } = this.props;
    const {
      valueSelectStatus,
      valueSelectItemStatus,
      listaStatus,
      listaItensStatus,
    } = this.state;

    if (valueSelectStatus !== "" && valueSelectItemStatus !== "") {
      const selectedStatus = listaStatus.find(
        (status) => status._id === valueSelectStatus
      );
      const selectedItemStatus = listaItensStatus.find(
        (item) => item._id === valueSelectItemStatus
      );

      if (selectedStatus && selectedItemStatus) {
        const nextStatus = {
          status: selectedStatus.status,
          status_id: selectedStatus._id,
          itemStatus: selectedItemStatus.titulo,
          itemStatus_id: selectedItemStatus._id,
        };

        const updatedStatus = [...this.state.status, nextStatus];

        this.setState({ status: updatedStatus }, () => {
          getStatus(updatedStatus);
        });
      } else {
        console.error("Status ou Item de Status não foram encontrados.");
      }
    } else {
      console.error("Status ou Item de Status não foram selecionados.");
    }
  }

  removeStatus = (idx) => () => {
    const { getStatus } = this.props;
    const updatedStatus = this.state.status.filter((_, sidx) => idx !== sidx);

    this.setState({ status: updatedStatus }, () => {
      getStatus(updatedStatus);
    });
  };

  render() {
    return (
      <div>
        {this.state.listaStatus && (
          <select
            className={css(styles.inputSelect)}
            value={this.state.valueSelectStatus}
            onChange={this.handleChangeStatus}
          >
            <option value="">Selecione um Status ---</option>
            {this.state.listaStatus.map((status, index) => (
              <option value={status._id} key={index}>
                {status.status}
              </option>
            ))}
          </select>
        )}

        {this.state.listaItensStatus.length > 0 && (
          <select
            className={css(styles.inputSelect)}
            value={this.state.valueSelectItemStatus}
            onChange={this.handleChangeItemStatus}
          >
            <option value="">Selecione um Item de Status ---</option>
            {this.state.listaItensStatus.map((item, index) => (
              <option value={item._id} key={index}>
                {item.titulo}
              </option>
            ))}
          </select>
        )}
        <Button text="Adicionar" color="primary" onClick={this.addStatus} />

        {this.state.status.map((status, idx) => (
          <div key={idx} className={css(styles.row_2)}>
            <fieldset className={css(styles.fieldset)}>
              <label className={css(styles.fieldset)}>
                <strong>{status.status}</strong>
              </label>
              <br></br>
              <label> {status.itemStatus}</label>
            </fieldset>

            <fieldset className={css(styles.fieldset)}>
              <span
                className={css(styles.link, styles.red)}
                onClick={this.removeStatus(idx)}
              >
                Remover
              </span>
            </fieldset>
          </div>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}

export default connect(mapStateToProps)(StatusLista);
