import {
  Box,
  Card,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Description, GetApp } from "@material-ui/icons";
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";

export default function ListBackupFiles({ files }) {
  const handleDownloadFile = async (fileId, fileName) => {
    try {
      const response = await axios({
        url: `/api/clinic/files/backup/${fileId}`,
        method: "GET",
        responseType: "blob",
      });
      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.click();
    } catch (error) {
      toast.error("Erro ao obter arquivo");
    }
  };

  return (
    <>
      <Typography variant="h6">Backup Dental Manager</Typography>
      <List>
        {files.length
          ? files.map(({ filename, url, _id }, idx) => (
              <>
                <ListItem key={idx}>
                  <ListItemIcon>
                    <Description />
                  </ListItemIcon>
                  <ListItemText primary={filename} />
                  <ListItemSecondaryAction>
                    <Tooltip title="Download">
                      <IconButton
                        onClick={() => handleDownloadFile(_id, filename)}
                      >
                        <GetApp />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            ))
          : null}
      </List>
    </>
  );
}
