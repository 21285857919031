import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError } from "redux-form";

import { css } from "aphrodite/no-important";
import { styles } from "./PatientFormStyles";

import {
  createPatientFull,
  getPatient,
  clearPatient,
  updatePatient,
} from "../../../actions/patientsCreation";
import { showMessageSystem } from "../../../actions/systemMsg";

import Button from "../../common/Button";
import InputField from "../../forms/InputField";
import DateTimePicker from "../date/DateTimePicker";
import TelephoneList from "../../lists/TelephoneList";
import SituacaoLista from "../../lists/SituacaoLista";
import StatusLista from "../../lists/StatusLista";
import ObservacaoLista from "../../lists/ObservacaoLista";
import PatientAddress from "./PatientAddress";
import { validaCpf } from "../../../helpers/Helpers";
import DialogEmail from "../../common/DialogEmail";

const PATIENT = {
  name: { name: "name", label: "Nome", placeholder: "Nome do(a) paciente" },
  lastName: {
    name: "lastName",
    label: "Sobrenome",
    placeholder: "Sobrenome do(a) paciente",
  },
  email: { name: "email", label: "E-mail", placeholder: "paciente@email.com" },
  registry: {
    name: "registry",
    label: "Número de Registro",
    placeholder: "XXXXXXXX",
  },
  tempoTratamento: {
    name: "tempoTratamento",
    label: "Tempo de tratamento",
    placeholder: "",
  },

  telNumber: {
    name: "telNumber",
    label: "Telefone",
    placeholder: "(XX) XXXXX XXXX",
    mask: "(99) 99999 9999",
  },
  telType: {
    name: "telType",
    label: "Tipo",
    placeholder: "Ex.: Celular, Principal...",
  },

  zip: {
    name: "zip",
    label: "CEP",
    placeholder: "XXXXX - XXX",
    mask: "99999 - 999",
  },
  address: {
    name: "address",
    label: "Endereço",
    placeholder: "Ex.: Av. Brasil, 999",
  },
  state: {
    name: "state",
    label: "Estado",
    placeholder: "Ex.: Paraná, São Paulo...",
  },
  city: {
    name: "city",
    label: "Cidade",
    placeholder: "Ex.: Curitiba, São Paulo...",
  },

  situacoes: { name: "situacoes", label: "Situações", type: "select" },
  status: { name: "status", label: "Status", type: "select" },
  observacoes: { name: "observacoes", label: "Observações", type: "text" },

  profession: {
    name: "profession",
    label: "Profissão",
    placeholder: "Profissão",
  },
  company: { name: "company", label: "Empresa", placeholder: "Empresa" },
  rg: {
    name: "civil_id",
    label: "RG",
    placeholder: "Ex.:XXX.XXX.XXX - X",
  },
  cpf: {
    name: "cpf",
    label: "CPF",
    placeholder: "XXX.XXX.XXX - XX",
    mask: "999.999.999 - 99",
  },
  father: { name: "father", label: "Nome do pai", placeholder: "Nome do pai" },
  mother: { name: "mother", label: "Nome da mãe", placeholder: "Nome da mãe" },
  father_profession: {
    name: "father_profession",
    label: "Profissão do pai",
    placeholder: "Profissão do pai",
  },
  mother_profession: {
    name: "mother_profession",
    label: "Profissão da mãe",
    placeholder: "Profissão da mãe",
  },
  insurance: {
    name: "insurance",
    label: "Plano Odontológico",
    placeholder: "Plano Odontológico",
  },
  insurance_number: {
    name: "insurance_number",
    label: "Número do Plano Odontológico",
    placeholder: "XXX.XXX.XXX.XXX",
  },
  sponsor: {
    name: "sponsor",
    label: "Responsável",
    placeholder: "Nome do responsável",
  },
  sponsor_insurance_number: {
    name: "sponsor_insurance_number",
    label: "Número do titular do Plano",
    placeholder: "XXX.XXX.XXX.XXX",
  },
  sponsor_cpf: {
    name: "sponsor_cpf",
    label: "CPF do responsável",
    placeholder: "XXX.XXX.XXX - XX",
    mask: "999.999.999 - 99",
  },
  indication: {
    name: "indication",
    label: "Indicação",
    placeholder: "Indicação",
  },
};

// PatientForm handles the form where the user enter the app
class PatientForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onBirthChange = this.onBirthChange.bind(this);
    this.dateFirstAppoimentChange = this.dateFirstAppoimentChange.bind(this);
    this.dateReturnChange = this.dateReturnChange.bind(this);
    this.renderFields = this.renderFields.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.getTelephones = this.getTelephones.bind(this);
    this.getSituacoes = this.getSituacoes.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.getAddress = this.getAddress.bind(this);
    this.getObservacoes = this.getObservacoes.bind(this);
    this.getTelError = this.getTelError.bind(this);

    this.state = {
      birth: null,
      dateFirstAppoiment: null,
      dateReturn: null,
      telephones: [],
      telError: null,
      situacoes: [],
      status: [],
      observacoes: [],
      openDialogEmail: false,
      emailModels: [],
      mentions: [],
      gender: "",
    };
  }

  componentDidMount() {
    const { selectedPatient } = this.props;

    if (selectedPatient) {
      this.setState({
        birth: selectedPatient.birthday,
        dateFirstAppoiment: selectedPatient.dateFirstAppoiment,
        dateReturn: selectedPatient.dateReturn,
        telephones: selectedPatient.telephones,
        situacoes: selectedPatient.situacoes,
        status: selectedPatient.status,
        observacoes: selectedPatient.observacoes,
        gender: selectedPatient.gender,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedPatient } = this.props;

    if (
      selectedPatient &&
      selectedPatient._id !== nextProps.selectedPatient._id
    ) {
      this.setState({
        birth: nextProps.selectedPatient.birthday,
        dateFirstAppoiment: nextProps.selectedPatient.dateFirstAppoiment,
        dateReturn: nextProps.selectedPatient.dateReturn,
        telephones: nextProps.selectedPatient.telephones,
        situacoes: nextProps.selectedPatient.situacoes,
        status: nextProps.selectedPatient.status,
        observacoes: nextProps.selectedPatient.observacoes,
        gender: nextProps.selectedPatient.gender,
      });
    } else if (selectedPatient) {
      this.setState({
        birth: selectedPatient.birthday,
        dateFirstAppoiment: selectedPatient.dateFirstAppoiment,
        dateReturn: selectedPatient.dateReturn,
        telephones: selectedPatient.telephones,
        situacoes: selectedPatient.situacoes,
        status: selectedPatient.status,
        observacoes: selectedPatient.observacoes,
        gender: selectedPatient.gender,
      });
    }
  }

  onBirthChange(birth) {
    this.setState({
      birth: birth,
    });
  }

  dateFirstAppoimentChange(dateFirstAppoiment) {
    this.setState({
      dateFirstAppoiment: dateFirstAppoiment,
    });
  }

  dateReturnChange(dateReturn) {
    this.setState({
      dateReturn: dateReturn,
    });
  }

  getTelephones(tel) {
    this.state.telephones = tel;
  }

  getSituacoes(situacao) {
    this.state.situacoes = situacao;
  }

  getStatus(tStatus) {
    this.state.status = tStatus;
  }

  getAddress(address) {
    this.setState({
      zip: address.zip,
      address: address.address,
      city: address.city,
    });
  }

  getObservacoes(observacao) {
    this.state.observacoes = observacao;
  }

  getTelError(telError) {
    this.state.telError = telError;
  }

  onCloseDialogEmail = () => {
    this.setState({ openDialogEmail: false });
  };

  onSubmit(values) {
    const {
      showMessageSystem,
      createPatientFull,
      updatePatient,
      selectedPatient,
      clinic,
      match,
      history,
    } = this.props;
    if (values.cpf) {
      if (validaCpf(values.cpf) === false) {
        showMessageSystem("Informe o CPF válido!", "warning");

        throw new SubmissionError({
          cpf: "CPF inválido",
        });
      }
    }

    if (!values.name || (values.name && values.name.trim().length <= 0)) {
      showMessageSystem("Informe o nome do paciente!", "warning");

      throw new SubmissionError({
        name: "Informe o nome do paciente!",
      });
    }

    values.clinic_id = clinic._id;
    values.telephones = this.state.telephones.filter((tel) => tel.value !== "");

    values.zip = this.state.zip;
    values.address = this.state.address;
    values.city = this.state.city;
    values.gender = this.state.gender;

    values.status = this.state.status.filter(
      (tStatus) => tStatus.status_id && tStatus.itemStatus_id
    );
    values.status.map((status) => {
      if (!status.status_id) delete status.status_id;
      if (!status.itemStatus_id) delete status.itemStatus_id;
      return status;
    });
    values.situacoes = this.state.situacoes.filter(
      (situacao) => situacao.value !== ""
    );
    values.observacoes = this.state.observacoes.filter(
      (observacao) => observacao.value !== ""
    );
    if (this.state.birth) values.birthday = this.state.birth;
    if (this.state.dateFirstAppoiment)
      values.dateFirstAppoiment = this.state.dateFirstAppoiment;
    if (this.state.dateReturn) values.dateReturn = this.state.dateReturn;

    if (!this.state.telError) {
      if (match.params.patientId === "registration") {
        createPatientFull(values, (ret) => {
          history.push(`/patients/profile/${ret._id}`);
        });
      } else {
        updatePatient(values, selectedPatient._id, (ret) => {
          history.push(`/patients/profile/${ret._id}`);
        });
      }
    }
  }

  renderFields(fields, props) {
    return fields.map((field) => (
      <Field
        key={field.name}
        type={field.type || "text"}
        name={field.name}
        label={field.label}
        placeholder={field.placeholder || ""}
        mask={field.mask || ""}
        listSelect={props && props}
        component={InputField}
      />
    ));
  }

  renderForm() {
    const { match, handleSubmit } = this.props;
    let btnText = "Atualizar Paciente";

    if (match.params.patientId === "registration") {
      btnText = "Criar Paciente";
    }

    return (
      <form className={css(styles.form)} onSubmit={handleSubmit(this.onSubmit)}>
        <h3 className={css(styles.sectionTitle)}>Informações básicas</h3>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            paddingRight: "20px",
          }}
        >
          <div> </div>
          <Button
            text={btnText}
            color="green"
            onClick={handleSubmit(this.onSubmit)}
          />
        </div>
        <div className={css(styles.section)}>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.registry])}
          </div>
          {this.props.selectedPatient && (
            <div>
              <fieldset style={{ margin: 0 }} className={css(styles.fieldset)}>
                <label className={css(styles.label)}>Tempo de Tratamento</label>
              </fieldset>
              <div style={{ marginBottom: "1rem" }}>
                {this.props.selectedPatient.tempoTratamento}
              </div>
            </div>
          )}
          <div className={css(styles.row_3)}>
            <DateTimePicker
              name="dateFirstAppoiment"
              value={this.state.dateFirstAppoiment}
              label="Data primeira consulta"
              onChange={this.dateFirstAppoimentChange}
            />
          </div>
          <div className={css(styles.row_3)}>
            <DateTimePicker
              name="dateReturn"
              value={this.state.dateReturn}
              label="Data do retorno"
              onChange={this.dateReturnChange}
            />
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.name])}
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.lastName])}
          </div>
          <div className={css(styles.row_3)}>
            <DateTimePicker
              name="birthday"
              value={this.state.birth}
              label="Data de Nascimento"
              onChange={this.onBirthChange}
            />
          </div>
        </div>

        {/* Situacoes */}
        <h3 className={css(styles.sectionTitle)}>Situações</h3>
        <div className={css(styles.section)}>
          <SituacaoLista
            situacoes={this.state.situacoes}
            getSituacoes={this.getSituacoes}
          />
        </div>

        {/* Status */}
        <h3 className={css(styles.sectionTitle)}>Status</h3>
        <div className={css(styles.section)}>
          <StatusLista status={this.state.status} getStatus={this.getStatus} />
        </div>

        {/* --- CONTATO --- */}
        <h3 className={css(styles.sectionTitle)}>Contato</h3>
        <div className={css(styles.section)}>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.email])}
          </div>
          <TelephoneList
            telephones={this.state.telephones}
            getTelephones={this.getTelephones}
            getTelError={this.getTelError}
          />
        </div>

        <h3 className={css(styles.sectionTitle)}>Endereço</h3>
        <div className={css(styles.section)}>
          <PatientAddress
            {...this.props.selectedPatient}
            getAddress={this.getAddress}
          />
        </div>

        <h3 className={css(styles.sectionTitle)}>Informações Avançadas</h3>
        <div className={css(styles.section)}>
          <div className={css(styles.row_3)}>
            {this.renderFields([PATIENT.cpf, PATIENT.rg])}
          </div>
          <div className={css(styles.row_1)}>
            <select
              name="gender"
              value={this.state.gender}
              onChange={(e) => {
                this.setState({ ...this.state, gender: e.target.value });
              }}
            >
              <option value="m">Masculino</option>
              <option value="f">Feminino</option>
            </select>
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.profession])}
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.company])}
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.mother])}
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.mother_profession])}
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.father])}
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.father_profession])}
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.sponsor])}
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.sponsor_cpf])}
          </div>
        </div>

        <h3 className={css(styles.sectionTitle)}>
          Informações do Plano de Saúde
        </h3>
        <div className={css(styles.section)}>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.insurance])}
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.insurance_number])}
          </div>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.sponsor_insurance_number])}
          </div>
        </div>

        <h3 className={css(styles.sectionTitle)}>Indicação</h3>
        <div className={css(styles.section)}>
          <div className={css(styles.row_1)}>
            {this.renderFields([PATIENT.indication])}
          </div>
        </div>
        {/* Observacoes */}
        <h3 className={css(styles.sectionTitle)}>Observações</h3>
        <div className={css(styles.section)}>
          <ObservacaoLista
            observacoes={this.state.observacoes}
            getObservacoes={this.getObservacoes}
          />
        </div>
        <Button text={btnText} color="green" submit />
        <Button
          text="Enviar e-mail"
          color="green2"
          icon="mail"
          right
          onClick={() => this.setState({ openDialogEmail: true })}
        />
      </form>
    );
  }

  render() {
    return (
      <div className={css(styles.flex)}>
        {this.renderForm()}
        {this.state.openDialogEmail && (
          <DialogEmail
            list={[this.props.selectedPatient]}
            onClose={this.onCloseDialogEmail}
            typeMentions={["patient", "receiver"]}
          />
        )}
      </div>
    );
  }
}

const patientForm = reduxForm({
  form: "patientForm",
})(PatientForm);

function mapStateToProps(state) {
  const selectedPatient = state.patientsCreation.selectedPatient;
  let initialValues = {};

  if (selectedPatient) {
    initialValues = selectedPatient;
    const milissegundosPorDia = 86400000;
    const diasTratamento = Math.floor(
        (new Date(selectedPatient.dateReturn).setHours(0, 0, 0, 0) -
            new Date(selectedPatient.dateFirstAppoiment).setHours(0, 0, 0, 0)) / 
            milissegundosPorDia
    ) + 1;
    
    let tempoTratamento;
    
    if (diasTratamento >= 365) {
        const anos = Math.floor(diasTratamento / 365);
        const diasRestantes = diasTratamento % 365;
        const meses = Math.floor(diasRestantes / 30);
        const diasFinais = diasRestantes % 30;

        if (anos > 0 && meses > 0 && diasFinais > 0) {
            tempoTratamento = `${anos} ano${anos > 1 ? 's' : ''}, ${meses} mês${meses > 1 ? 'es' : ''} e ${diasFinais} dia${diasFinais > 1 ? 's' : ''}`;
        } else if (anos > 0 && meses > 0) {
            tempoTratamento = `${anos} ano${anos > 1 ? 's' : ''} e ${meses} mês${meses > 1 ? 'es' : ''}`;
        } else if (anos > 0 && diasFinais > 0) {
            tempoTratamento = `${anos} ano${anos > 1 ? 's' : ''} e ${diasFinais} dia${diasFinais > 1 ? 's' : ''}`;
        } else if (anos > 0) {
            tempoTratamento = `${anos} ano${anos > 1 ? 's' : ''}`;
        } else if (meses > 0 && diasFinais > 0) {
            tempoTratamento = `${meses} mês${meses > 1 ? 'es' : ''} e ${diasFinais} dia${diasFinais > 1 ? 's' : ''}`;
        } else if (meses > 0) {
            tempoTratamento = `${meses} mês${meses > 1 ? 'es' : ''}`;
        } else {
            tempoTratamento = `${diasFinais} dia${diasFinais > 1 ? 's' : ''}`;
        }
    } else if (diasTratamento >= 30) {
        const meses = Math.floor(diasTratamento / 30);
        const diasRestantes = diasTratamento % 30;
        
        if (meses > 0 && diasRestantes > 0) {
            tempoTratamento = `${meses} mês${meses > 1 ? 'es' : ''} e ${diasRestantes} dia${diasRestantes > 1 ? 's' : ''}`;
        } else if (meses > 0) {
            tempoTratamento = `${meses} mês${meses > 1 ? 'es' : ''}`;
        } else {
            tempoTratamento = `${diasRestantes} dia${diasRestantes > 1 ? 's' : ''}`;
        }
    } else {
        tempoTratamento = `${diasTratamento} dia${diasTratamento > 1 ? 's' : ''}`;
    }

    selectedPatient.tempoTratamento = tempoTratamento;
}

  return {
    clinic: state.auth.clinic,
    user: state.auth.user,
    selectedPatient: state.patientsCreation.selectedPatient,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  showMessageSystem,
  createPatientFull,
  getPatient,
  clearPatient,
  updatePatient,
})(patientForm);
