import React from "react";
import ListBackupFiles from "./ListBackupFiles";
import { connect } from "react-redux";
import { Container, Paper } from "@material-ui/core";

function BackupView({ clinicFilesBackup }) {
  return (
    <Container component={Paper} style={{ padding: "16px" }} maxWidth="lg">
      <ListBackupFiles files={clinicFilesBackup} />
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    clinicFilesBackup: state.auth?.clinic?.files?.backup ?? [],
  };
}
export default connect(mapStateToProps)(BackupView);
