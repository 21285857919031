import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { IconButton } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { buscarTabelaPrecoProtetico } from "../../../../api/proteticos";
import { formataMoedaReal } from "../../../../helpers/Helpers";
import TableComponent from "../../../table/TableComponent";

function ListaTabelaPrecoProtetico({ history, match }) {
  const [tabelaPreco, setTabelaPreco] = useState([]);
  const colunas = [
    { label: "Código", name: "codigo" },
    { label: "Descrição", name: "descricao" },
    {
      label: "Valor",
      name: "valor",
      options: {
        customBodyRender: (value) => {
          return value && formataMoedaReal(value);
        },
      },
    },
    {
      label: "Status",
      name: "ativo",
      options: {
        customBodyRender: (value) => {
          return value ? "Ativo" : "Inativo";
        },
      },
    },
    {
      label: "Funções",
      name: "_id",
      options: {
        customBodyRender: (value) => {
          return (
            <IconButton onClick={() => editarTabelaPreco(value)}>
              <Edit />
            </IconButton>
          );
        },
      },
    },
  ];
  const options = {
    customToolbar: () => {
      return (
        <IconButton onClick={inserirTabelaPrecoNovo}>
          <Add />
        </IconButton>
      );
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      const tabela = await buscarTabelaPrecoProtetico(
        match.params.protetico_id,
      );
      setTabelaPreco(tabela);
    };
    fetchData();
  }, []);

  const inserirTabelaPrecoNovo = () => {
    history.push(`${match.url}/item/novo`);
  };

  const editarTabelaPreco = (id) => {
    history.push(`${match.url}/item/${id}`);
  };

  return (
    <TableComponent colunas={colunas} objetos={tabelaPreco} options={options} />
  );
}

export default ListaTabelaPrecoProtetico;
