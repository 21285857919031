import React, { Component } from "react";
import { connect } from "react-redux";
import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import Paper from "@material-ui/core/Paper";
import {
  buscarConta,
  cadastrarConta,
  editarConta,
  buscarDescricoes,
} from "../../../../api/contasPagar";
import FormUpdateExpensesPaid from "./ExpensesPaid";
import moment from "moment";
import { Checkbox, FormControlLabel } from "@material-ui/core";

class ContasPagarCadastro extends Component {
  state = {
    formulario: [
      { type: "text", name: "nome", label: "Nome do Credor" },
      { type: "text", name: "nota", label: "Nota" },
      { type: "text", name: "duplicada", label: "Duplicata" },
      { type: "date", name: "dataVenc", label: "Data de Vencimento" },
      { type: "number", name: "valor", label: "Valor Total" },
      { type: "number", name: "qtdParcelas", label: "Nº de Parcelas" },
      // { type: "number", name: "totalPrestacao", label: "Total Parcelas" },
      { type: "text", name: "observacao", label: "Observação" },
    ],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    conta: "",
    cadastro: true,
    await: true,
    buttonDisabled: false,
    dividirValor: false,
  };

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.conta_id) {
      // var objConta = await buscarConta(
      //   clinica._id,
      //   this.props.match.params.conta_id
      // );
      this.setState({ cadastro: false, await: false });
    } else {
      this.setState({ await: false });
    }

    var objDescricao = await buscarDescricoes(clinica._id);
    var formulario = this.state.formulario;

    var selectDescricao = {
      type: "select",
      name: "descricao",
      label: "Tipo de Conta",
      values: [],
    };

    objDescricao.map((obj) => {
      var novo = {};
      novo.name = obj.descricao;
      novo.value = obj._id;
      selectDescricao.values.push(novo);
    });

    formulario.push(selectDescricao);
    this.setState({
      await: false,
      conta_id: this.props.match.params.conta_id,
      formulario: formulario,
    });
  }

  handleDividirValorChange = (event) => {
    const isChecked = event.target.checked;
    this.setState({ dividirValor: isChecked });
  };

  editaConta(objeto) {
    this.setState({ buttonDisabled: true });
    editarConta(objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Conta editada com sucesso",
              abrir: true,
            },
          });
          this.props.history.goBack();
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o lançamento da Conta!",
              abrir: true,
            },
          });
        }
      })
      .then(() => this.setState({ buttonDisabled: false }));
  }

  salvarConta(objeto) {
    this.setState({ buttonDisabled: true });
    const { clinica } = this.props;
    const { dividirValor } = this.state;
    objeto.dividirValor = dividirValor;

    objeto.dataVenc = moment(objeto.dataVenc).utc().toDate();

    cadastrarConta(clinica, objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Conta cadastrada com sucesso",
              abrir: true,
            },
          });
          this.props.history.goBack();
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o lançamento da conta!",
              abrir: true,
            },
          });
        }
      })
      .then(() => this.setState({ buttonDisabled: false }));
  }

  render() {
    return this.state.cadastro ? (
      <div>
        <Paper>
          {this.state.alerta.abrir && (
            <Notificacao
              tipo={this.state.alerta.tipo}
              msg={this.state.alerta.msg}
              onClose={() =>
                this.setState({
                  alerta: { ...this.state.alerta, abrir: false },
                })
              }
            />
          )}
          {!this.state.await && (
            <>
              <FormComponent
                {...this.props}
                inputs={this.state.formulario}
                objeto={this.state.conta}
                disabledAction={this.state.buttonDisabled}
                action={
                  this.state.cadastro
                    ? this.salvarConta.bind(this)
                    : this.editaConta.bind(this)
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.dividirValor}
                    onChange={this.handleDividirValorChange}
                    color="primary"
                  />
                }
                label="Dividir valor entre as parcelas"
              />
            </>
          )}
        </Paper>
      </div>
    ) : (
      <div>
        <Paper>
          <FormUpdateExpensesPaid
            clinicId={this.props.clinica._id}
            expenseId={this.props.match.params.conta_id}
            history={this.props.history}
          />
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ContasPagarCadastro);
