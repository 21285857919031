import React, { Component } from "react";
import { connect } from "react-redux";

// import Particles from 'react-particles-js';

import { logOutUser, getClinic } from "../../actions/auth";
import { fetchMaterials } from "../../actions/storage";
import { css } from "aphrodite/no-important";
import { styles } from "./SideMenuStyles";
import { NavLink } from "react-router-dom";

import UserOptions from "./UserOptions";
import SideLink from "./SideLink";

import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icone from "@material-ui/core/Icon";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const LINKS = [
  { text: "Resumo", path: "", icon: "home", exact: true },
  { text: "Agenda", path: "agenda", icon: "date_range" },
  { text: "Pacientes", path: "pacientes", icon: "people" },
  { text: "Dentistas", path: "dentistas", icon: "assignmentInd" },
  { text: "Serviços", path: "servicos", icon: "receipt" },
  { text: "Protéticos", path: "proteticos", icon: "recent_actors" },
  {
    text: "Financeiro",
    icon: "account_balance",
    submenu: [
      {
        text: "Fluxo de caixa",
        icon: "account_balance_wallet",
        path: "financeiro/fluxoCaixa",
      },
      {
        text: "Faturas",
        path: "financeiro/pacientes/resumo",
        icon: "people",
      },
      {
        text: "Dentistas",
        path: "financeiro/dentistas/comissoes",
        icon: "assignmentInd",
      },
      // { text: 'Recebimentos', path: 'recebimentos', icon:'attach_money' },
      { text: "Despesas", path: "contas", icon: "money_off" },
    ],
  },
  { text: "Convênios", path: "convenios", icon: "card_membership" },
  { text: "Produtos", path: "storage", icon: "storage" },
  {
    text: "Relatórios",
    icon: "description",
    submenu: [
      {
        text: "Prontuário",
        path: "relatorios/prontuario",
      },
      {
        text: "Endodontia",
        path: "relatorios/endodontia",
      },
    ],
  },
  { text: "Backup Dental Manager", path: "backup", icon: "backup" },
];

const LINKS_PLATFORM = [
  { text: "Clientes", path: "admin", icon: "home", exact: true },
  {
    text: "Usuários plataforma",
    path: "admin/users",
    icon: "person",
    exact: true,
  },
];

// 'SideMenu' will manage the main navigationof the app
class SideMenu extends Component {
  state = {
    open: "",
  };

  constructor(props) {
    super(props);

    this.onLogOut = this.onLogOut.bind(this);
    this.renderLinks = this.renderLinks.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (menu) => {
    this.setState({ open: this.state.open == menu ? "" : menu });
  };

  componentDidMount() {
    const { fetchMaterials, getClinic, clinic } = this.props;

    if (clinic) {
      fetchMaterials();

      const clinicId = clinic._id;
      const clinicDb = clinic.clinic_data;

      getClinic({ clinicId, clinicDb }, () => {
        const { clinic } = this.props;
        this.setState({
          loading: false,
          logo: { src: clinic.logo_clinic, file: "" },
        });
      });
    }
  }

  onLogOut() {
    const { history, logOutUser } = this.props;

    logOutUser(() => {
      history.push("/login");
    });
  }

  renderLinks() {
    const { match, materialsById, user } = this.props;

    const mapLinks =
      user && user.professional_platform ? LINKS_PLATFORM : LINKS;
    return mapLinks.map((link, index) => {
      if (link.submenu) {
        return (
          <a href={void 0} key={index} className={css(styles.navLink)}>
            <ListItem button onClick={() => this.handleClick(link.text)}>
              <ListItemIcon>
                <Icone style={{ color: "#fff" }}>{link.icon}</Icone>
              </ListItemIcon>
              <ListItemText disableTypography={true} primary={link.text} />
              {this.state.open == link.text ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={this.state.open == link.text}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {link.submenu.map((sub, index) => (
                  <NavLink
                    key={index}
                    exact={sub.exact}
                    to={match.url + sub.path}
                    activeClassName={css(styles.active)}
                    className={css(styles.navLink)}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <Icone style={{ color: "#fff" }}>{sub.icon}</Icone>
                      </ListItemIcon>
                      <ListItemText
                        disableTypography={true}
                        primary={sub.text}
                        style={{ color: "#ffffff" }}
                      />
                      <span
                        style={sub.warning ? {} : { display: "none" }}
                        className={css(styles.warning)}
                      >
                        {" "}
                        {sub.warning}{" "}
                      </span>
                    </ListItem>
                  </NavLink>
                ))}
              </List>
            </Collapse>
          </a>
        );
      }

      if (link.path == "storage") {
        let warnings = null;

        for (var key in materialsById) {
          if (materialsById[key].quantity <= materialsById[key].min) {
            warnings++;
          }
        }
        return (
          <SideLink
            key={index}
            text={link.text}
            path={match.url + link.path}
            icon={link.icon}
            exact={link.exact}
            warning={warnings}
          />
        );
      } else {
        return (
          <SideLink
            key={index}
            text={link.text}
            path={match.url + link.path}
            icon={link.icon}
            exact={link.exact}
          />
        );
      }
    });
  }

  render() {
    const { history, match, clinic, user } = this.props;

    return (
      <div className={css(styles.sideMenu)}>
        <UserOptions history={history} />

        <List component="nav" className={css(styles.bg)}>
          {this.renderLinks()}
          {!user?.professional_platform && (
            <>
              <SideLink
                text="Configurações"
                path={`${match.url}configuration`}
                icon="settings"
              />
            </>
          )}
          <SideLink
            text="Importador"
            path={`${match.url}import`}
            icon="upload"
          />

          <SideLink text="Sair" path="/login" icon="exit_to_app" />
        </List>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    materialsById: state.storage.materialsById,
    clinic: auth.clinic,
    user: auth.user,
  };
}

export default connect(mapStateToProps, {
  logOutUser,
  fetchMaterials,
  getClinic,
})(SideMenu);
