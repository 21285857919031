import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import { Description, GetApp } from "@material-ui/icons";
import Axios from "axios";
import { toast } from "react-toastify";

export default function ClinicalNoteBackupDialog({
  patientId,
  open,
  onClose,
  files,
}) {
  const handleGetImage = async (fileName) => {
    try {
      const response = await Axios({
        url: `/api/patient/${patientId}/files/clinical-notes/${fileName}`,
        method: "GET",
        responseType: "blob",
      });
      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.click();
    } catch (error) {
      toast.error("Error ao obter arquivo");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Backup acompanhamento clínico</DialogTitle>
      <DialogContent>
        <List>
          {files.length
            ? files.map(({ filename, _id }, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <Description />
                  </ListItemIcon>
                  <ListItemText primary={filename} />
                  <ListItemSecondaryAction
                    onClick={() => handleGetImage(filename)}
                  >
                    <IconButton>
                      <GetApp />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            : null}
        </List>
      </DialogContent>
      <DialogActions>
        <Button disableElevation onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
