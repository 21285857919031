import React, { useState, useEffect } from "react";
import CashFlowList from "./CashFlowList";
import {
  Button,
  Paper,
  Card,
  Typography,
  CardContent,
  TableContainer,
  Grid,
} from "@material-ui/core";
import { formataMoedaReal } from "../../../../helpers/Helpers";
import { listCashFlow } from "../../../../api/cash_flow.js";
import { toast } from "react-toastify";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const defaultCashFlow = {
  data: [],
  in: 0,
  out: 0,
};

export default function CashFlow() {
  const [cashFlow, setCashFlow] = useState(defaultCashFlow);
  const [startDate, setStartDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await listCashFlow(startDate, finalDate);
        setCashFlow(result.data);
      } catch (e) {
        toast.error("Erro ao obter fluxo de caixa");
      }
    };
    fetchData();
  }, []);

  const handleSearch = async () => {
    try {
      const { data } = await listCashFlow(startDate, finalDate);
      setCashFlow(data);
    } catch (e) {
      toast.error("Erro ao obter fluxo de caixa");
    }
  };

  return (
    <TableContainer component={Paper} style={{ padding: "16px" }}>
      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
        <KeyboardDatePicker
          label="Data Inicial"
          format="dd/MM/yyyy"
          style={{
            marginBottom: "30px",
            marginRight: "10px",
            marginLeft: "170px",
            marginTop: "10px",
          }}
          inputVariant="outlined"
          value={startDate}
          onChange={(e) => setStartDate(e)}
        />
      </MuiPickersUtilsProvider>

      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
        <KeyboardDatePicker
          label="Data Final"
          format="dd/MM/yyyy"
          style={{ marginBottom: "30px", marginTop: "10px" }}
          inputVariant="outlined"
          value={finalDate}
          onChange={(e) => setFinalDate(e)}
        />
      </MuiPickersUtilsProvider>

      <Button
        disableElevation
        variant="contained"
        color="primary"
        style={{ marginLeft: "10px", marginBottom: "30px", marginTop: "10px" }}
        onClick={handleSearch}
      >
        Buscar
      </Button>
      <CashFlowResume
        entrance={cashFlow.in}
        out={cashFlow.out}
        balance={cashFlow.balance}
      />
      <CashFlowList dados={cashFlow.data} />
    </TableContainer>
  );
}

function CashFlowResume({ entrance, out, balance }) {
  return (
    <Paper>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <Card style={{ maxWidth: "250px" }}>
            <CardContent>
              <Typography color="textSecondary">Total entradas</Typography>
              <Typography style={{ color: "green" }} variant="h6">
                {formataMoedaReal(entrance)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card style={{ maxWidth: "250px" }}>
            <CardContent>
              <Typography color="textSecondary">Total saidas</Typography>
              <Typography style={{ color: "red" }} variant="h6">
                {formataMoedaReal(-out)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card style={{ maxWidth: "250px" }}>
            <CardContent>
              <Typography color="textSecondary">Saldo</Typography>
              <Typography variant="h6">{formataMoedaReal(balance)}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
}
