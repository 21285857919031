import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Notificacao from "../../../notificacao/Notificacao";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import {
  buscarFichaAnamnese,
  editarFichaAnamnese,
} from "../../../../api/pacientes";

const useStyles = {
  painel: {
    padding: "20px 0 80px 0",
  },
  root: {
    width: "95%",
    margin: "0 auto",
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  formulario: {
    marginBottom: "20px",
  },
};

const campoDefault = {
  tipo: "",
  titulo: "",
  ativo: true,
  obrigatorio: false,
  opcao: {
    nome: "",
    riscoVida: false,
  },
  opcoes: [],
};

class PacientesConfigFichaAnamnese extends Component {
  constructor(props) {
    super(props);
    this.novoGrupo = this.novoGrupo.bind(this);
    this.novoCampo = this.novoCampo.bind(this);
  }
  state = {
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    situacao: "",
    fichas: [],
    fichasOriginais: {},
    cadastro: true,
    await: true,
    popupGrupo: false,
    popupCampo: false,
    expanded: false,
    novoGrupo: "",
    errorNovoGrupo: false,
    grupoCampo: "",
    novoCampo: campoDefault,
    errorNovoCampo: {
      tipo: false,
      titulo: false,
    },
    campoEditar: {
      editar: false,
    },
    disabledButton: false,
  };

  async componentWillMount() {
    var fichas = await buscarFichaAnamnese();
    this.setState({
      await: false,
      fichas: fichas,
      fichasOriginais: JSON.parse(JSON.stringify(fichas)),
    });
  }

  async editarFicha() {
    try {
      await editarFichaAnamnese(this.state.fichas);
      toast.success("Ficha salva com sucesso!");
      const fichas = await buscarFichaAnamnese();
      this.setState({
        await: false,
        fichas: fichas,
        fichasOriginais: JSON.parse(JSON.stringify(fichas)),
      });
    } catch (e) {
      toast.error("Erro ao salvar, tente novamente mais tarde!");
    }
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  dialogHandleChange = (dialog) => {
    this.setState({ [dialog]: !this.state[dialog], disabledButton: false });
  };

  removerGrupo = (indexGrupo) => {
    const fichas = this.state.fichas;
    if (fichas[indexGrupo]._id) {
      fichas[indexGrupo].ativo = false;
    } else {
      fichas.splice(indexGrupo, 1);
    }
    this.setState({ fichas: fichas });
  };

  novoGrupo() {
    this.setState({ ...this.state, disabledButton: true });
    if (!this.state.novoGrupo) {
      this.setState({ ...this.state, errorNovoGrupo: true });
      return;
    }
    const novoGrupo = {
      titulo: this.state.novoGrupo,
      ativo: true,
      campos: [],
    };
    const fichas = this.state.fichas;

    if (!fichas) {
      fichas = [];
    }

    fichas.push(novoGrupo);

    this.setState({
      ficha: fichas,
      popupGrupo: false,
      novoGrupo: campoDefault,
      errorNovoCampo: {
        titulo: false,
        tipo: false,
      },
    });
  }

  editarCampo() {
    if (!this.state.novoCampo.titulo) {
      this.setState({});
    }
    const fichas = this.state.fichas;
    fichas[this.state.campoEditar.indexGrupo].campos[
      this.state.campoEditar.indexCampo
    ] = this.state.novoCampo;
    this.setState({
      ficha: fichas,
      popupCampo: false,
      novoCampo: campoDefault,
      errorNovoCampo: {
        titulo: false,
        tipo: false,
      },
    });
  }

  removerCampo(indexGrupo, indexCampo) {
    const fichas = this.state.fichas;
    const campos = fichas[indexGrupo].campos;
    if (campos[indexCampo]._id) {
      campos[indexCampo].ativo = false;
    } else {
      campos.splice(indexCampo, 1);
    }
    fichas[indexGrupo].campos = campos;
    this.setState({
      fichas: fichas,
      errorNovoCampo: {
        titulo: false,
        tipo: false,
      },
    });
  }

  novoCampo() {
    if (!this.state.novoCampo.titulo) {
      this.setState({
        ...this.state,
        errorNovoCampo: {
          ...this.state.errorNovoCampo,
          titulo: true,
        },
      });
      return;
    }
    const fichas = this.state.fichas;
    fichas[this.state.grupoCampo].campos.push(this.state.novoCampo);
    this.setState({
      fichas: fichas,
      grupoCampo: "",
      popupCampo: false,
      novoCampo: campoDefault,
      errorNovoCampo: {
        titulo: false,
        tipo: false,
      },
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.painel}>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
          />
        )}

        {!this.state.await && (
          <div className={classes.root}>
            <div style={{ textAlign: "right" }}>
              <Button
                style={{ marginBottom: "20px" }}
                variant="contained"
                color="primary"
                onClick={() => this.dialogHandleChange("popupGrupo")}
              >
                Nova Ficha
              </Button>
            </div>
            {this.state.fichas.length &&
              this.state.fichas.map(
                (grupo, indexGrupo) =>
                  grupo.ativo && (
                    <ExpansionPanel key={indexGrupo}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={grupo.titulo}
                        id={grupo.titulo}
                      >
                        <IconButton
                          onClick={() => this.removerGrupo(indexGrupo)}
                          edge="start"
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>

                        <Typography className={classes.heading}>
                          {grupo.titulo}
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <List>
                              {grupo.campos.map(
                                (campo, index) =>
                                  campo.ativo && (
                                    <ListItem
                                      onClick={() => {
                                        this.setState((prevState) => ({
                                          ...prevState.campoEditar,
                                          campoEditar: {
                                            editar: true,
                                            campo: campo,
                                            indexCampo: index,
                                            indexGrupo: indexGrupo,
                                          },
                                          novoCampo: campo,
                                          popupCampo: true,
                                        }));
                                      }}
                                    >
                                      <ListItemText primary={campo.titulo} />
                                      <ListItemSecondaryAction>
                                        <IconButton
                                          onClick={() =>
                                            this.removerCampo(indexGrupo, index)
                                          }
                                          edge="end"
                                          aria-label="delete"
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  ),
                              )}
                            </List>
                          </Grid>
                          <Button
                            style={{ marginBottom: "20px" }}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              this.dialogHandleChange("popupCampo");
                              this.setState({
                                grupoCampo: indexGrupo,
                                campoEditar: { editar: false },
                                novoCampo: campoDefault,
                              });
                            }}
                          >
                            Novo campo
                          </Button>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ),
              )}

            <Dialog
              open={this.state.popupGrupo}
              onClose={() => this.dialogHandleChange("popupGrupo")}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Novo grupo</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Crie fichas para separar as informações no formulario.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="grupo"
                  label="Título do grupo"
                  type="text"
                  fullWidth
                  error={this.state.errorNovoGrupo}
                  helperText={
                    this.state.errorNovoGrupo &&
                    "Informe um título para o grupo"
                  }
                  onChange={(e) => this.setState({ novoGrupo: e.target.value })}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.dialogHandleChange("popupGrupo")}>
                  Fechar
                </Button>
                <Button
                  onClick={() => this.novoGrupo()}
                  color="primary"
                  variant="contained"
                  disabled={this.state.disabledButton}
                  disableElevation
                >
                  Criar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.popupCampo}
              onClose={() => this.dialogHandleChange("popupCampo")}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Novo campo</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Crie campos para montar seu formulário
                </DialogContentText>

                <FormControl
                  style={{ width: "100%" }}
                  error={this.state.errorNovoCampo.tipo}
                >
                  <InputLabel id="demo-simple-select-label">
                    Tipo do campo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.novoCampo.tipo}
                    autoFocus
                    onChange={(e) =>
                      this.setState((prevState) => ({
                        novoCampo: {
                          ...prevState.novoCampo,
                          tipo: e.target.value,
                        },
                      }))
                    }
                  >
                    <MenuItem value={"Discursive"}>Discursiva</MenuItem>
                    <MenuItem value={"Boolean"}>Lógica</MenuItem>
                    <MenuItem value={"Select"}>Seleção</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  margin="dense"
                  id="titulo"
                  label="Nome do campo"
                  type="text"
                  fullWidth
                  error={this.state.errorNovoCampo.titulo}
                  helperText={
                    this.state.errorNovoCampo.titulo &&
                    "Informe um nome para o campo"
                  }
                  value={this.state.novoCampo.titulo}
                  onChange={(e) => {
                    let titulo = e.target.value;

                    this.setState((prevState) => ({
                      novoCampo: {
                        ...prevState.novoCampo,
                        titulo: titulo,
                      },
                    }));
                  }}
                />

                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.novoCampo.obrigatorio}
                        onChange={(e) => {
                          let ativo = e.target.checked;

                          this.setState((prevState) => ({
                            novoCampo: {
                              ...prevState.novoCampo,
                              obrigatorio: ativo,
                            },
                          }));
                        }}
                      />
                    }
                    label="Campo obrigatório"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.novoCampo.ativo}
                        onChange={(e) => {
                          let ativo = e.target.checked;

                          this.setState((prevState) => ({
                            novoCampo: {
                              ...prevState.novoCampo,
                              ativo: ativo,
                            },
                          }));
                        }}
                        color="primary"
                      />
                    }
                    label="Ativar campo"
                  />
                </FormGroup>
                {this.state.novoCampo.tipo === "Select" && (
                  <>
                    <FormGroup row>
                      <div>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="opcoes"
                          label="Opções"
                          type="text"
                          value={
                            this.state.novoCampo.opcao
                              ? this.state.novoCampo.opcao.nome
                              : ""
                          }
                          onChange={(e) => {
                            let opcao = e.target.value;

                            this.setState((prevState) => ({
                              novoCampo: {
                                ...prevState.novoCampo,
                                opcao: {
                                  nome: opcao,
                                  riscoVida: prevState.novoCampo.opcao
                                    ? prevState.novoCampo.opcao.riscoVida
                                    : false,
                                },
                              },
                            }));
                          }}
                        />
                        <FormControlLabel
                          style={{ marginLeft: "10px", marginTop: "25px" }}
                          control={
                            <Checkbox
                              checked={
                                this.state.novoCampo.opcao
                                  ? this.state.novoCampo.opcao.riscoVida
                                  : false
                              }
                              onChange={(e) => {
                                const riscoVida = e.target.checked;

                                this.setState((prevState) => ({
                                  novoCampo: {
                                    ...prevState.novoCampo,
                                    opcao: {
                                      nome: prevState.novoCampo.opcao
                                        ? prevState.novoCampo.opcao.nome
                                        : "", // Garantir que nome sempre tenha um valor
                                      riscoVida,
                                    },
                                  },
                                }));
                              }}
                              color="primary"
                            />
                          }
                          label="Risco de vida"
                        />
                      </div>
                      <Fab
                        onClick={() => {
                          this.setState((prevState) => ({
                            novoCampo: {
                              ...prevState.novoCampo,
                              opcoes: [
                                ...prevState.novoCampo.opcoes,
                                this.state.novoCampo.opcao,
                              ],
                            },
                          }));
                        }}
                        size="small"
                        color="secondary"
                        aria-label="add"
                      >
                        <AddIcon />
                      </Fab>
                    </FormGroup>
                    <List>
                      {this.state.novoCampo.opcoes.map((opcao, index) => (
                        <ListItem>
                          <ListItemText primary={opcao.nome ?? ""} />
                          <ListItemText
                            primary={opcao.riscoVida ? "Risco de vida" : ""}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={() => {
                                this.setState((prevState) => ({
                                  novoCampo: {
                                    ...prevState.novoCampo,
                                    opcoes: [
                                      ...prevState.novoCampo.opcoes.slice(
                                        0,
                                        index,
                                      ),
                                      ...prevState.novoCampo.opcoes.slice(
                                        index + 1,
                                      ),
                                    ],
                                  },
                                }));
                              }}
                              edge="end"
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}

                {this.state.novoCampo.tipo === "Boolean" && (
                  <>
                    <FormGroup row>
                      <div>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="opcoes"
                          label="Opções"
                          type="text"
                          value={
                            this.state.novoCampo.opcao
                              ? this.state.novoCampo.opcao.nome
                              : ""
                          }
                          onChange={(e) => {
                            let opcao = e.target.value;

                            this.setState((prevState) => ({
                              novoCampo: {
                                ...prevState.novoCampo,
                                opcao: {
                                  nome: opcao,
                                  riscoVida: prevState.novoCampo.opcao
                                    ? prevState.novoCampo.opcao.riscoVida
                                    : false,
                                },
                              },
                            }));
                          }}
                        />
                        <FormControlLabel
                          style={{ marginLeft: "10px", marginTop: "25px" }}
                          control={
                            <Checkbox
                              checked={
                                this.state.novoCampo.opcao
                                  ? this.state.novoCampo.opcao.riscoVida
                                  : false
                              }
                              onChange={(e) => {
                                const riscoVida = e.target.checked;

                                this.setState((prevState) => ({
                                  novoCampo: {
                                    ...prevState.novoCampo,
                                    opcao: {
                                      nome: prevState.novoCampo.opcao
                                        ? prevState.novoCampo.opcao.nome
                                        : "", // Garantir que nome sempre tenha um valor
                                      riscoVida,
                                    },
                                  },
                                }));
                              }}
                              color="primary"
                            />
                          }
                          label="Risco de vida"
                        />
                      </div>
                      <Fab
                        onClick={() => {
                          this.setState((prevState) => ({
                            novoCampo: {
                              ...prevState.novoCampo,
                              opcoes: [
                                ...prevState.novoCampo.opcoes,
                                this.state.novoCampo.opcao,
                              ],
                            },
                          }));
                        }}
                        size="small"
                        color="secondary"
                        aria-label="add"
                      >
                        <AddIcon />
                      </Fab>
                    </FormGroup>
                    <List>
                      {this.state.novoCampo.opcoes.map((opcao, index) => (
                        <ListItem>
                          <ListItemText primary={opcao.nome ?? ""} />
                          <ListItemText
                            primary={opcao.riscoVida ? "Risco de vida" : ""}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={() => {
                                this.setState((prevState) => ({
                                  novoCampo: {
                                    ...prevState.novoCampo,
                                    opcoes: [
                                      ...prevState.novoCampo.opcoes.slice(
                                        0,
                                        index,
                                      ),
                                      ...prevState.novoCampo.opcoes.slice(
                                        index + 1,
                                      ),
                                    ],
                                  },
                                }));
                              }}
                              edge="end"
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => this.dialogHandleChange("popupCampo")}
                  color="primary"
                >
                  Cancel
                </Button>
                {this.state.campoEditar.editar ? (
                  <Button onClick={() => this.editarCampo()} color="primary">
                    Editar
                  </Button>
                ) : (
                  <Button onClick={() => this.novoCampo()} color="primary">
                    Adicionar
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Button
              style={{
                marginBottom: "20px",
                position: "absolute",
                bottom: 0,
                left: "28px",
              }}
              variant="contained"
              color="primary"
              onClick={() => this.editarFicha()}
            >
              Salvar
            </Button>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}

export default withStyles(useStyles)(
  connect(mapStateToProps)(PacientesConfigFichaAnamnese),
);
