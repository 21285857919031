import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  buscarFichaAtm,
  buscarFichaAtmPaciente,
  editarFichaAtmPaciente,
} from "../../../../api/pacientes";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Container,
  Button,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Paper,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  InputLabel,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import { Add, ExpandMore, LocalHospital, Warning } from "@material-ui/icons";
import { showMessageSystem } from "../../../../actions/systemMsg";

function PacientesFichaATM({ selectedPatient, showMessageSystem }) {
  const [fichas, setFichas] = useState({
    modelos: [],
    formulario: [],
    dialog: false,
  });
  const [idFichaSelecionada, setIdFichaSelecionada] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const modelosFichas = await buscarFichaAtm();
      const pacienteFichas = await buscarFichaAtmPaciente(selectedPatient._id);
      const modelosAtivos = modelosFichas.filter(
        (modelo) => modelo.ativo === true,
      );

      let form = [];

      if (pacienteFichas) {
        form = montaFormulario(modelosFichas, pacienteFichas);
      }

      setFichas({
        modelos: modelosAtivos,
        formulario: form,
      });
    };

    fetchData();
  }, [selectedPatient]);

  const montaFormulario = (modelosFichas, pacienteFichas) => {
    return pacienteFichas.grupos.map((grupo) => {
      const modeloGrupo = JSON.parse(
        JSON.stringify(modelosFichas.find((ficha) => ficha._id === grupo._id)),
      );
      modeloGrupo.campos = modeloGrupo.campos.map((campo) => {
        const campoValue = grupo.campos.find(
          (element) => element._id === campo._id,
        );
        if (campoValue) {
          campo.value = campoValue.value;
        }
        return campo;
      });
      return modeloGrupo;
    });
  };

  const adicionarFicha = () => {
    if (!idFichaSelecionada) {
      setError(true);
      return;
    }
    const modeloFicha = fichas.modelos.find(
      (modelo) => modelo._id === idFichaSelecionada,
    );
    setFichas({
      ...fichas,
      formulario: [...fichas.formulario, modeloFicha],
      dialog: false,
    });
  };

  const changeValueForm = (valor, indexFicha, indexCampo) => {
    const form = [...fichas.formulario];
    const campo = { ...form[indexFicha].campos[indexCampo], value: valor };
    form[indexFicha].campos[indexCampo] = campo;
    setFichas({ ...fichas, formulario: form });
  };

  const salvarFichas = async () => {
    const objSalvar = {
      paciente_id: selectedPatient._id,
      formulario: fichas.formulario,
    };
    try {
      await editarFichaAtmPaciente(objSalvar);
      showMessageSystem("Alteração efetuada com sucesso!", "success");
    } catch (err) {
      showMessageSystem("Erro ao salvar", "error");
    }
  };

  const renderCampos = (campo, indexFicha, indexCampo) => {
    switch (campo.tipo) {
      case "Discursive":
        return (
          <TextField
            fullWidth
            value={campo.value ?? ""}
            variant="outlined"
            onChange={(e) =>
              changeValueForm(e.target.value, indexFicha, indexCampo)
            }
            InputProps={{
              startAdornment: campo.riscoVida && (
                <InputAdornment position="start" title="Risco de vida">
                  <LocalHospital style={{ color: "red" }} />
                </InputAdornment>
              ),
            }}
          />
        );
      case "Select":
        // Encontrar a opção selecionada com base no valor atual
        const opcaoSelecionada = campo.opcoes.find(
          (opcao) => opcao.nome === campo.value[0],
        );

        const inputProps = {};

        // Verificar se a opção selecionada tem riscoVida
        if (opcaoSelecionada && opcaoSelecionada.riscoVida) {
          inputProps.startAdornment = (
            <InputAdornment position="start">
              <LocalHospital style={{ color: "red" }} />
            </InputAdornment>
          );
        }

        return (
          <TextField
            select
            variant="outlined"
            value={campo.value}
            fullWidth
            InputProps={{ ...inputProps }}
            onChange={(e) =>
              changeValueForm(e.target.value, indexFicha, indexCampo)
            }
          >
            {campo.opcoes.map((opcao, index) => (
              <MenuItem key={index} value={opcao.nome}>
                {opcao.nome}
              </MenuItem>
            ))}
          </TextField>
        );

      case "Boolean":
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            {campo.opcoes.map((opcao, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={(campo.value || []).includes(opcao.nome)}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      const newValue = isChecked
                        ? [...(campo.value || []), opcao.nome]
                        : (campo.value || []).filter(
                            (item) => item !== opcao.nome,
                          );

                      changeValueForm(newValue, indexFicha, indexCampo);
                    }}
                  />
                }
                label={opcao.nome}
              />
            ))}
          </div>
        );
    }
  };
  return (
    <>
      <Paper style={{ padding: "20px" }}>
        <div style={{ textAlign: "right" }}>
          <Button
            style={{ marginBottom: "16px", textAlign: "right" }}
            onClick={() => setFichas({ ...fichas, dialog: true })}
            startIcon={<Add />}
            variant="contained"
            color="secondary"
          >
            NOVO
          </Button>
        </div>
        {fichas.formulario.length <= 0 ? (
          <Typography style={{ textAlign: "center" }}>
            Paciente sem ficha ATM! Adicione uma nova ficha para inciar.
          </Typography>
        ) : (
          fichas.formulario.map((ficha, indexFicha) => (
            <>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                  {ficha.titulo}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{ display: "block", width: "100%" }}>
                    {ficha.campos.map((campo, indexCampo) => (
                      <div style={{ marginTop: "12px" }}>
                        <Typography>{campo.titulo}</Typography>
                        {renderCampos(campo, indexFicha, indexCampo)}
                      </div>
                    ))}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </>
          ))
        )}
        <div style={{ textAlign: "right", marginTop: "16px" }}>
          <Button color="primary" variant="contained" onClick={salvarFichas}>
            Salvar
          </Button>
        </div>
      </Paper>
      <Dialog
        open={fichas.dialog}
        onClose={() => setFichas({ ...fichas, dialog: false })}
      >
        <DialogTitle>Adicionar ficha</DialogTitle>
        <DialogContent>
          <Typography>Selecione um modelo de ficha para adicionar</Typography>
          <FormControl fullWidth error={error}>
            <Select
              value={idFichaSelecionada}
              onChange={(e) => setIdFichaSelecionada(e.target.value)}
            >
              {fichas.modelos.map((modelo) => (
                <MenuItem value={modelo._id} key={modelo._id}>
                  {modelo.titulo}
                </MenuItem>
              ))}
            </Select>
            {error && (
              <FormHelperText>Nenhum modelo selecionado</FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={adicionarFicha}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return { selectedPatient: state.patientsCreation.selectedPatient };
};

export default connect(mapStateToProps, { showMessageSystem })(
  PacientesFichaATM,
);
