import React, { useEffect, useState } from "react";
import TableComponent from "../../table/TableComponent";
import {
  Container,
  Paper,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { getDentistComissions } from "../../../api/dentist_comission";
import { formataMoedaReal } from "../../../helpers/Helpers";
import moment from "moment";
import { toast } from "react-toastify";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const defaultListDentistComission = {
  data: [],
  in: 0,
  out: 0,
};

export default function ListDentistComission() {
  const [dentistComissions, setDentistComissions] = useState([]);
  const [startDate, setStartDate] = useState(null); // Inicialmente nulo
  const [finalDate, setFinalDate] = useState(null); // Inicialmente nulo
  const [dentistName, setDentistName] = useState(""); // Estado para o nome do dentista
  const [loading, setLoading] = useState(false); // Estado para carregamento

  const columns = [
    { name: "dentist", label: "Dentista" },
    { name: "procedure", label: "Procedimento" },
    { name: "procedureConcludedDate", label: "Data de conclusão" },
    { name: "value", label: "Valor" },
  ];

  // Função para buscar dados com base nos filtros ou sem filtros
  const fetchData = async (startDate = null, finalDate = null, dentistName = "") => {
    setLoading(true);
    try {
      const { data } = await getDentistComissions(startDate, finalDate);
      const filteredData = data
        .filter((element) =>
          element.dentist.name.toLowerCase().includes(dentistName.toLowerCase())
        )
        .map((element) => {
          return {
            value: formataMoedaReal(element.value),
            procedure: element.procedure.procedure?.description || "",
            dentist: element.dentist.name,
            procedureConcludedDate: moment(
              element.procedure.procedure?.concludedDate || new Date()
            ).format("DD/MM/YYYY"),
          };
        });
      setDentistComissions(filteredData);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao obter comissões");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Carregar todas as comissões inicialmente
  }, []);

  const handleSearch = () => {
    fetchData(startDate, finalDate, dentistName); // Buscar dados com base nos filtros fornecidos
  };

  return (
    <Container maxWidth="lg" component={Paper}>
      <Typography
        variant="h5"
        fontWeight="bold"
        style={{ marginTop: "30px", marginBottom: "20px" }}
      >
        Financeiro Dentistas
      </Typography>

      <TextField
        label="Nome do Dentista"
        variant="outlined"
        value={dentistName}
        onChange={(e) => setDentistName(e.target.value)}
        style={{ marginRight: "20px" }}
      />

      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
        <KeyboardDatePicker
          label="Data Inicial"
          format="dd/MM/yyyy"
          inputVariant="outlined"
          value={startDate}
          onChange={(date) => setStartDate(date)}
          style={{ marginRight: "20px", marginLeft: "10px" }}
        />
      </MuiPickersUtilsProvider>

      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
        <KeyboardDatePicker
          label="Data Final"
          format="dd/MM/yyyy"
          inputVariant="outlined"
          value={finalDate}
          onChange={(date) => setFinalDate(date)}
        />
      </MuiPickersUtilsProvider>

      <Button
        disableElevation
        variant="contained"
        color="primary"
        style={{ marginLeft: "10px", marginBottom: "30px", marginTop: "10px" }}
        onClick={handleSearch}
        disabled={loading} // Desabilita o botão durante o carregamento
      >
        Buscar
      </Button>

      {loading ? (
        <Typography variant="body1" style={{ marginTop: "20px" }}>
          Carregando...
        </Typography>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <TableComponent colunas={columns} objetos={dentistComissions} />
        </div>
      )}
    </Container>
  );
}
