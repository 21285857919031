import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import Axios from "axios";
import { connect } from "react-redux";
import LoadingButton from "../../buttons/LoadingButton";
import { toast } from "react-toastify";

function ClinicUploadPatient(props) {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  // handle file upload
  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const sendImport = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("filename", file.name);
      await Axios.post("/api/patient/migrarPacientes", formData);
      toast.success('Pacientes importados com sucesso')
    } catch (error) {
      toast.error('Error ao importar');
    }
    setLoading(false);
  };

  return (
    <Box>
      <Typography style={{ marginBottom: "16px" }}>
        Importador de pacientes
      </Typography>
      <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
      <LoadingButton title="Enviar" onClick={sendImport} loading={loading} />
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    clinic: state.auth.clinic,
  };
}

export default connect(mapStateToProps)(ClinicUploadPatient);
